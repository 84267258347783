var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { eventDetailSubject, marketMapping, sportsbookFilter, sportsbookList } from "../../store/sportsbook.store";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { initialLeftMenu, sportsMenu, useForceUpdate } from "../../lib/misc.functions";
// import {useNavigate} from "react-router-dom";
import _ from "lodash";
import { SideCompetitionList } from "./widgets/competitionlist";
import { fillEventStatistics, generateCompetitionGroupList, } from "../../services/sportsbook.services";
import AlertTriangle from "../../assets/feather/alert-triangle.svg";
import { EventDetail, eventDetailVisiblity } from "./eventdetail";
import { getEvent, getEventComments } from "../../services/sosyoley.services";
import { SportsBookFilter } from "./widgets/filters";
import { betSlipVisiblity } from "../../shared/betslip/betslip";
import { Eventlist } from "./widgets/eventlist";
import { EventlistHeader } from "./components/eventlist-header";
import { SportsList } from "../home/home";
import { newMarkets } from "../../definitions/constants/market-categories.constant";
import { sosyoleyEventIdMapping } from "../sosyoley/subpages/posts";
import { eventEndedStatuses } from "../../definitions/constants/event-result-type";
var clearFilters = function () {
    sportsbookFilter.filterKeyword = '';
    sportsbookFilter.filterDays = [];
    sportsbookFilter.filterMBS = [];
    sportsbookFilter.filterCompetition = [];
    sportsbookFilter.filterLiveStream = false;
};
var SportsBook = function () {
    // const navigate = useNavigate();
    var params = useParams();
    var forceUpdate = useForceUpdate();
    var width = window.innerWidth;
    useEffect(function () {
        clearFilters();
        closeEventDetail();
        forceUpdate();
    }, [params.eventType, params.sportSlug]);
    useEffect(function () {
        if (width > 968) {
            betSlipVisibleSubject.next(true);
        }
        return function () {
            betSlipVisibleSubject.next(false);
            document.body.style.overflow = 'unset';
        };
    }, []);
    if (width < 969 && betSlipVisiblity) {
        document.body.style.overflow = 'hidden';
    }
    else {
        document.body.style.overflow = 'unset';
    }
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible, params.sportSlug, params.eventType]);
    useEffect(function () {
        var eventDetailSubject_subscription = eventDetailSubject.subscribe(function (res) {
            if (res && res === 'toggle') {
                eventDetailVisiblity.visible = false;
                closeEventDetail();
                forceUpdate();
            }
        });
        return function () {
            eventDetailSubject_subscription.unsubscribe();
        };
    }, []);
    var openEventDetail = function (event) {
        if (!event.comments && event) {
            getEventComments(event.eventId).then(function (response) {
                // console.log(response)
                event.comments = response;
            });
        }
        if (event) {
            var sportsBookWrapper = document.getElementById("sportsBookWrapper");
            if (sportsBookWrapper) {
                sportsBookWrapper.classList.add('opened-detail');
            }
            eventDetailSubject.next(event);
            var opendEvent = document.getElementsByClassName("opened");
            if (opendEvent[0]) {
                opendEvent[0].classList.remove("opened");
            }
            var eventDiv = document.getElementById("eventId_" + event.eventId);
            if (eventDiv) {
                eventDiv.classList.add("opened");
            }
            if (width < 969) {
                document.body.style.overflow = 'hidden';
            }
        }
    };
    var closeEventDetail = function () {
        var opendEvent = document.getElementsByClassName("opened");
        if (opendEvent[0]) {
            opendEvent[0].classList.remove("opened");
        }
        eventDetailSubject.next(false);
        eventDetailVisiblity.visible = false;
        var sportsBookWrapper = document.getElementById("sportsBookWrapper");
        if (sportsBookWrapper) {
            sportsBookWrapper.classList.remove('opened-detail');
        }
        document.body.style.overflow = 'unset';
    };
    // console.log(sportsbookList.filter((el:any)=>el.sportSlug=='duello'))
    var originSportsbookList = _.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
        if (params.sportSlug === el.sportSlug) {
            if ((params.eventType === 'tumu' && Number(el.eventType) === 1) ||
                (params.eventType === 'canli' && Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                (params.eventType === 'uzunvadeli' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                return el;
            }
        }
    });
    // console.log("originSportsbookList>>",originSportsbookList)
    var shownSportsBook = [];
    // fixme : performans iyileştirme lazım
    shownSportsBook = originSportsbookList.filter(function (el) {
        var filteredElement = el;
        if ((Number(filteredElement.isLiveEvent) === 0 || (Number(filteredElement.bettingPhase) === 0 && eventEndedStatuses.includes(filteredElement.liveScoreStatus))) &&
            marketMapping[String(filteredElement.eventId)].filter(function (m) { return Number(m.marketStatus) !== 0; }).length === 0) {
            el.isRemoved = true;
            filteredElement = null;
        }
        else {
            el.isRemoved = false;
        }
        if (filteredElement && sportsbookFilter.filterCompetition.length > 0) {
            if (sportsbookFilter.filterCompetition.indexOf(el.competitionId) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterRapid === true) {
            if (marketMapping[filteredElement.eventId].filter(function (el) { return newMarkets.includes(el.marketType + '-' + el.marketSubType) && (el.marketStatus === 1 || el.marketStatus < 0); }).length > 0) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterCustomRatio === true) {
            if (el.hasCustomratio === true) {
                filteredElement = el;
            }
            else if (el.additionalBonus === true) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterMBS.length > 0) {
            if (sportsbookFilter.filterMBS.indexOf(el.minCombiCount) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterDays.length > 0) {
            // console.log(sportsbookFilter.filterDays)
            if (sportsbookFilter.filterDays.indexOf(el.eventDate.substring(0, 10)) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterLiveStream) {
            if (el.liveStreamAvailable === 1 || el.liveStreamAvailable === 2) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterLiveEvent) {
            if (Number(el.isLiveEvent) === 1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterAlarmSet) {
            if (el.alarmStatus === true) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement && sportsbookFilter.filterKeyword.length > 1) {
            if (el.eventName.toLowerCase().indexOf(sportsbookFilter.filterKeyword.toLowerCase()) !== -1) {
                filteredElement = el;
            }
            else {
                filteredElement = null;
            }
        }
        if (filteredElement) {
            return filteredElement;
        }
    });
    // console.log("shownSportsBook>>>", shownSportsBook.length)
    // fixme : tekrar tekrar çalışıyor buna bir performans iyileştirme yapmak lazım
    // fixme: sadece filtre vs de çalışmaları daha sonra sadece güncellemeler için kullanılacak
    var competitionGroups = useMemo(function () { return generateCompetitionGroupList(originSportsbookList); }, [params.sportSlug]);
    useEffect(function () {
        var _a;
        if (params.eventId) {
            var reloadEvent = sportsbookList.find(function (el) { return Number(el.eventId) === Number(params.eventId); });
            if (reloadEvent) {
                openEventDetail(reloadEvent);
            }
            else {
                getEvent(Number(params.eventId)).then(function (event) {
                    event.isEnded = true;
                    sosyoleyEventIdMapping[Number(event.eventId)] = event;
                    marketMapping[event.eventId] = event.markets;
                    fillEventStatistics([Number(params.eventId)]).finally(function () {
                        openEventDetail(sosyoleyEventIdMapping[Number(params.eventId)]);
                    });
                });
                // setTimeout(() => {
                //   modalServiceSubject.next({
                //     title: 'Maç Sonu',
                //     content: 'Bu karşılaşma artık bültenden kaldırılmış bu sebepten maç detay gürüntülenemez.',
                //     confirm: {
                //       cancel: { label: 'Tamam' }
                //     },
                //     timeout: 3000
                //   })
                // }, 300)
            }
        }
        else {
            if (params.competitionId) {
                (_a = sportsbookFilter.filterCompetition).push.apply(_a, params.competitionId.split(','));
            }
            closeEventDetail();
        }
    }, [params.eventId, params.competitionId]);
    var searchTimer;
    var setKeywordFilter = function (keyword) {
        clearTimeout(searchTimer);
        if (keyword === '') {
            try {
                // @ts-ignore
                document.getElementById('id_KeywordFilter').value = '';
            }
            catch (e) {
                console.log(e);
            }
            sportsbookFilter.filterKeyword = keyword;
            forceUpdate();
        }
        else {
            searchTimer = setTimeout(function () {
                sportsbookFilter.filterKeyword = keyword;
                forceUpdate();
            }, 1200);
        }
    };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "row sportbook-wrapper" }, { children: [_jsx("div", __assign({ id: "compititationLeftMenu", className: "compititationLeftMenu" }, { children: _jsx(SideCompetitionList, { competitionGroups: competitionGroups, sportSlug: params.sportSlug, eventType: params.eventType }, void 0) }), void 0), _jsxs("div", __assign({ id: "sportsBookWrapper", className: "sportsBookWrapper" }, { children: [innerWidth < 968 ? _jsx("div", __assign({ style: { paddingLeft: 15, paddingRight: 15, zIndex: 0, position: 'relative' } }, { children: _jsx(SportsList, {}, void 0) }), void 0) : _jsx(_Fragment, {}, void 0), _jsx(SportsBookFilter, { params: params, forceUpdate: forceUpdate, competitionGroups: competitionGroups, setKeywordFilter: setKeywordFilter, originSportsbookList: originSportsbookList }, void 0), _jsx(EventlistHeader, { forceUpdate: forceUpdate, params: params }, void 0), _jsx(Eventlist, { openEventDetail: openEventDetail, sportsbookFilter: sportsbookFilter, params: params, shownSportsBook: shownSportsBook }, void 0), shownSportsBook.length === 0 ? _jsxs("div", __assign({ className: "text-center m-5" }, { children: [_jsx("p", { children: _jsx("img", { src: AlertTriangle, width: 46 }, void 0) }, void 0), _jsx("p", { children: "Arad\u0131\u011F\u0131n\u0131z kriterde g\u00F6sterilecek kar\u015F\u0131la\u015Fma bulunamad\u0131!" }, void 0), _jsx("p", { children: _jsx("a", __assign({ href: "#", className: "fw-normal fs-small ml-2", onClick: function () {
                                            clearFilters();
                                        } }, { children: "T\u00FCm\u00FCn\u00FC G\u00F6ster" }), void 0) }, void 0)] }), void 0) : _jsx(_Fragment, {}, void 0)] }), void 0), _jsx(EventDetail, { eventType: params.eventType, sportSlug: params.sportSlug, closeEventDetail: closeEventDetail, openEventDetail: openEventDetail }, void 0)] }), void 0) }, void 0));
};
export default SportsBook;
